import functions from '../functions.js';
import { list } from './list';

$(() => {
    listAccommodation.init();
});

const listAccommodation = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {
        personsInObject: 0,
        _page: 1,
        perPage: 12,
        language: functions.getLang(),
        pool: '',
        parking: '',
        pets: '',
        internet: '',
        dateFrom: '',
        dateTo: '',
        dates: null,
        destination: '',
        countryId: '',
        objectGroupId: 1,
        objectTypeId: null,
        serviceId: null,
        objectTypeId: '',
        guests: '',
        tagCategoryId: null,
        tagCategoryIds: null,
        categoryObject: null, 
        sortBy: null, 
        objectName: null, 
        distanceBeach: null, 
        distanceCenter: null,
    },
    params: {},
    isHome: false,
    $listItems: null,
    filterParams: {
        tagCategoryId: null,
        tagCategoryIds: null,
        categoryObject: null, 
        sortBy: null, 
        objectName: null, 
        distanceBeach: null, 
        distanceCenter: null, 
        pool: null, 
        parking: null, 
        pets: null, 
        internet: null
    },
    mainFields: ['dateFrom', 'dateTo', 'destination', 'objectTypeId', 'persons', 'personsInObject', 'sortBy', '_page'],
    init() {
        this.$form = $('.search-form.search-accommodation');
        this.form = this.$form[0];

        this.$filter = $('.search-filter');
        this.filter = this.$filter[0];  

        this.$listItems = $('.list-items');
        this.isHome = this.$form.last().attr('data-ishome') || ! this.$listItems.length;

        //this.listURL = this.$form.last().attr('data-listurl');
        this.defaults.objectGroupId = this.$form.attr('data-objectgroupid');
        this.defaults.countryId = this.defaults.destination = this.$form.attr('data-countryid');
        //this.defaults.linkedTypeId = this.$form.last().attr('data-linkedtypeid');
        //this.filterParams.tagCategoryId = this.$form.last().attr('data-tagcategoryid');

        if(! this.form) {
            return;
        }

        this.getParams();
        this.setDateTo();

        functions.setFormData(this.form, this.params);
        if(this.filter) {
            functions.setFormData(this.filter, this.params);
        }
        this.splitCalendarDates($('[name=dates]').val());

        if($('[name=guests]').val()) {
            this.params.guests = $('[name=guests]').val();
        }

        this.find(this.params);

        $('.input-child').on('change', e => {
            e.preventDefault();
            e.stopPropagation();
        });

        if( ! functions.isMobile() && ! this.isHome) {
            this.$form.on('change', e => {
                e.preventDefault();

                this.params = $.extend(this.params, this.defaults, this.filterParams, functions.getFormData(this.$form));
                this.params._page = this.defaults._page;

                this.setDateTo();

                functions.setUrlData(this.params, true, true);
            });
        }

        this.$form.on('submit', e => {
            const $target = $(e.target);
            e.preventDefault();

            this.isHome = $target.attr('data-ishome') || this.isHome;

            this.params = $.extend(this.params, this.defaults, this.filterParams, functions.getFormData(this.$form));
            this.params._page = this.defaults._page;
            this.params.dates = $('[name=dates]').val();
            this.setDateTo();

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                functions.removeNull(this.params);
                location = $target.attr('data-listurl') + '#' + $.param(this.params);
            } else {
                functions.setUrlData(this.params, true, true);
            }

        });

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.params.destination = '';
            $(this.form.destination).val('').trigger('change');
        });

        $('.btn-clear-filters').on('click', e => {
            e.preventDefault();
            list.params = this.params = $.extend(this.params, this.defaults);

            this.splitCalendarDates(this.params.dates);

            this.params = $.extend(this.params, this.defaults);
            this.params._page = this.defaults._page;

            functions.setUrlData(this.params, true, true);
            if ($('.search-accommodation')[1] || null) functions.setFormData($('.search-accommodation')[1], this.defaults);
            
            functions.setFormData(this.form, this.defaults);
            functions.setFormData(this.filter, this.defaults);
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.stopChangeEvents = true;

            functions.scrollToElement(this.$listItems, 100);

            this.find(this.params);
            this.stopChangeEvents = false;
        });

        $('[name=dates]').on('change', e => {
            const $target = $(e.target);
            if ($target.val().indexOf(' - ') !== -1) {
                $(".datepicker-actions").removeClass('d-none');
            } else {
                $(".datepicker-actions").addClass('d-none');
            }
        });

    },
    getParams() {
        list.params = this.params = list.getParams(this.params, this.defaults, this.filterParams);
        this.splitCalendarDates(this.params.dates);
    },
    setDateTo() {
        if(this.params.dateFrom && this.params.nights) {
            let dateFrom = new Date(this.params.dateFrom);
            let dateTo = dateFrom;
            dateTo.setDate(dateTo.getDate() + +this.params.nights);
            dateTo = dateTo.getFullYear() + '-' + ('0' + (+dateTo.getMonth() + 1)).slice(-2) + '-' + ('0' + dateTo.getDate()).slice(-2);
            this.params.dateTo = dateTo;
            $('[name=dateTo]').val(dateTo);
        } else {
            this.params.dateTo = null;
        }
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    find(params) {
        if (this.params.dateFrom && ! this.params.dateTo) return;

        list.find(params).then((response) => {
            list.findDone(response);
            // LOZAD
            window.observer.observe();
        });
    }
};