import functions from './functions';
import './details/details';
import './details/details-travel';
import './details/details-package';
import './details/details-accommodation';

$(() => {

    $('[data-bs-toggle="modal"][data-bs-tab]').on('click', e => {
        const $target = $(e.currentTarget);
        const unitId = $target.attr('data-unitid');
        const $tab = $('#modalUnit-' + unitId).find('[id=' + $target.attr('data-bs-tab') + ']');
        $tab.tab('show');
    });

    if($('[id^=itinerary-summary-slider]')[0]) {
        let itienerarySummarySlider = [];
        $('[id^=itinerary-summary-slider]').each((i, elem) => {
            const length = $(elem).find('.keen-slider__slide').length;
            itienerarySummarySlider[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: false,
                arrows: false,
                pager: false,
                slides: {
                    //origin: "center",
                    perView: "auto",
                    spacing: 0,
                }
            });
        });
    }

    // scroll by swipe
    if($('.itinerary-day-summary')[0]) {
        let width = 0;
        $('.itinerary-day-slide').each((index, slide) => {
            width += slide.clientWidth;
        });
    
        if($('.itinerary-day-summary')[0].clientWidth > width) {
            $('.itinerary-day-summary').addClass('justify-content-end')
        } else {
            $('.itinerary-day-summary').removeClass('justify-content-end')
            $('.itinerary-day-summary')[0].scrollTo({
                left: -width,
                behavior: 'smooth'
            });
        }
    
        $('.itinerary-day-summary').on('mousedown mousemove mouseup touchstart touchmove touchend', e => {
            const $target = $(e.currentTarget);
            const dir = functions.getSwipeDirection(e);
            if(dir != 0) {
                $target[0].scrollTo({
                    left: $target[0].scrollLeft + ($target[0].clientWidth * dir),
                    behavior: 'smooth'
                });
            }
    
        });
    }

    $('.itinerary-all-collapse').on('click', e => {
        const $target = $(e.currentTarget);
        const $days = $('[id^=collapseDay]');
        const $btn = $('btn-itinerary-day');
        const $btnIcon = $('.btn-itinerary-day i');

        const daysCount = $days.length;
        const shownCount = $('[id^=collapseDay].show').length;

        if(daysCount == shownCount) {
            $days.hide();
            $days.removeClass('show');
            $btn.addClass('collapsed');
            $btnIcon.css('transform', 'rotate(0)');
            $target.find('i').removeClass("la-arrow-up").addClass("la-arrow-down");
        } else {
            $days.show();
            $days.addClass('show');
            $btn.removeClass('collapsed');
            $btnIcon.css('transform', 'rotate(-180deg)');
            $target.find('i').removeClass("la-arrow-down").addClass("la-arrow-up");
        }
    });

    $('.show-more').on('click', function (e) {
        e.preventDefault();
        const $target = $(this);
        const $block = $target.closest('.truncate-block');
        const $dots = $block.find('.dots');

        if ( ! $dots.hasClass('d-none')) {
            $block.find('.more-text').removeClass('d-none');
            $dots.addClass('d-none');
            $target.find('small').html($target.attr('data-less') + '<i class="las la-arrow-up ms-1"></i>');
        } else {
            $block.find('.more-text').addClass('d-none');
            $dots.removeClass('d-none');
            $target.find('small').html($target.attr('data-more') + '<i class="las la-arrow-down ms-1"></i>');
        }
    });

});