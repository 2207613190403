import functions from '../functions';

let id = 0;
const instance = {};
const $deatils = $('#details-datepicker');
const deatils = $('#details-datepicker')[0];

class DeparturePicker {
    constructor(elem) {
        this.id = ++id;
        instance[this.id] = this;

        this.$elem = $(elem);

        this.$elem._flatpickr = flatpickr(elem, {
            altInput: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            closeOnSelect: true,
            disableMobile: true,
            onOpen: (selected, dateStr, instance) => {
                if(dateStr) {
                    this.setDurationPicker(this.departures, selected, dateStr, instance);
                }
            },
            onClose: (selected, dateStr, instance) => {
                //instance.setDate(dateStr);
                //this.$elem.parents('form').trigger('change');
            },
            onChange: (selected, dateStr, instance) => {
                if(dateStr === '') {
                    $('[name="dateTo"]').val('');
                }
                this.setDurationPicker(this.departures, selected, dateStr, instance);
                instance.setDate(dateStr);
                this.$elem.parents('form').trigger('change');
            },
            onValueUpdate: (dateObj, dateStr, instance) => {
                if(dateStr) {
                    this.setDurationPicker(this.departures, dateStr, dateStr, instance);
                }
            },
            onReady: (dateObj, dateStr, instance) => {
                //const $container = $(instance.calendarContainer);
                const $container = $('.duration-container');
                $container.append(this.printDurationPickerContainer());

                if(dateStr) {
                    this.setDurationPicker(this.departures, selected, dateStr, instance);
                }

                $(instance.element).change(e => {
                    if(e.originalEvent) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                });
                
            },
        });
        if(deatils) {
            this.$elem._flatpickr.set('clickOpens', true);
        }

        this.setDepartures();

        $('[name="dateTo"]').val(this.params.dateTo);

        $('.datepicker [data-clear]').on('click', e => {
            this.$elem._flatpickr.clear();
            this.$elem._flatpickr.set('minDate', this.firstDate);
            $('.choose-label').removeClass('d-none');
            $('.duration-picker').empty();
        });

        $(this.$elem._flatpickr.altInput).on('click', e => {

            if($('.form-guide')[0] && ! this.$elem._flatpickr.config.clickOpens) {
               setTimeout(() => {
                    $('.form-guide')[0]._formGuide.show();
                });
            }

        });

        /*$('[name="destination"]').on('change', e => {
            this.params.destination = $(e.currentTarget).val();
            this.setDepartures();
        });*/

        /*$('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.$elem._flatpickr.clear();
            this.$elem._flatpickr.set('clickOpens', false);
        });*/
        
        $(window).on('hashchange', e => {
            //this.setDepartures();
        });
    }

    printDurationPickerContainer() {
        return `<div class="duration-picker"></div>`;
        /*return `
            <div class="duration-picker text-start border-top border-primary px-3 py-2">
                <div class="fw-bold text-uppercase mb-1">Trajanje putovanja</div>
                <div class="choose-label text-danger">Izaberite datum sa kalendara</div>
                <div class="row d-none"></div>
            </div>
        `;*/
    }

    printDurationItem(departure, index) {
        return `
            <div class="form-check uration-item"
                data-dateFrom="${ departure.dateFrom }"
                data-dateTo="${ departure.dateTo }"
                data-index="${ index }">
                <input class="form-check-input" type="radio" name="dateTo" id="dateTo${ index }" value="${ departure.dateTo }" ${ index == 0 ? 'checked' : '' }>
                <label class="form-check-label fw-bold" for="dateTo${ index }">
                    <span class="ms-1">${ departure.duration } dana | Povratak: <strong>${ functions.formatDate(departure.dateTo) }</strong></span>
                </label>
            </div>
        `;
    }

    getParams() {
        //this.params = functions.getUrlData(true);
        this.params = $.extend({}, this.params, functions.getUrlData(true));
    }

    setDepartures() {
        this.getParams();

        if(deatils) {
            this.params.destination = $deatils.attr('data-countryid');
        } else {
            this.params.destination = $('.search-form').attr('data-countryid');
        }

        let params = this.params;
        params.countryId = params.destination && params.destination.split(':')[0];

        const getDepartures = this.getDepartures(params);
        
        if(getDepartures) {
            getDepartures.done(response => {
                this.departures = response;
                this.$elem._flatpickr.set('enable', this.getEnableDates(this.departures));

                this.firstDate = this.getEnableDates(this.departures)[0] ? this.getEnableDates(this.departures)[0] : null;
                this.$elem._flatpickr.set('minDate', this.firstDate);

                //this.$elem._flatpickr.set('clickOpens', true);
            }).fail(error => {
                console.log(error);
            });
        } else if(deatils && $deatils.attr('data-departures')) {
            this.departures = JSON.parse($deatils.attr('data-departures'));
            this.$elem._flatpickr.set('enable', this.getEnableDates(this.departures));
            //this.$elem._flatpickr.set('clickOpens', true);
        } else {
            //this.$elem._flatpickr.set('clickOpens', false);
        }

    }

    getDepartures(params) {
        if(params.countryId) {
            return $.getJSON(
                location.protocol
                + '//'
                + location.hostname
                + '/services/get_departures/'
                + '?countryId=' + params.countryId
            );
        }
        return null;
    }

    getEnableDates(departures) {
        let enable = [];
        if(departures) {
            Object.entries(departures).forEach(item => {
                const departure = item[1];
                enable.push(departure.dateFrom);
            });
            enable = enable.filter((item, index, array) => {
                return array.indexOf(item) === index;
            });
        }
        return enable;
    }

    setDurationPicker(departures, selected, dateStr, instance) {
        const $container = $('.duration-container');
        const $durationPicker = $('.duration-picker');
        const $chooseLabel = $container.find('.choose-label');


        if(departures.length) {

            $chooseLabel.addClass('d-none');

            let index = 0;

            departures.forEach(departure => {
                if(departure.dateFrom == dateStr) {
                    $durationPicker.empty().append(this.printDurationItem(departure, index));

                    $('.duration-item').find('.form-check-input').prop('checked', false);
                    if(this.params.dateTo && $('.duration-item[data-dateTo="' + this.params.dateTo + '"]')[0]) {
                        $('.duration-item[data-dateTo="' + this.params.dateTo + '"]').find('.form-check-input').prop('checked', true);
                        $('[name="dateTo"]').val(this.params.dateTo);
                    } else {
                        $('.duration-item[data-index="0"]').find('.form-check-input').prop('checked', true);
                        //index == 0 && $('[name="dateTo"]').val(departure.dateTo);
                        index == 0 && $('[name="dateTo"]').val(departure.dateTo);
                    }

                    index++;
                }
            });

            $('.duration-item').on('click', e => {
                const $target = $(e.currentTarget);
                this.params.dateTo = $target.attr('data-dateTo');
                
                $('[name="dateTo"]').val(this.params.dateTo).trigger('change');
            });

            /*$('[name="dateTo"]').on('change', e => {
                const $target = $(e.currentTarget);
                $('.duration-item[data-dateTo="' + $target.val() + '"]').find('[type="checkbox"]').prop('checked', true);
            });*/
        }

    }
}

export {
	DeparturePicker
};