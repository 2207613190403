import functions from './functions.js';

import Slider from "./components/Slider";
import { InputGuests } from "./components/input-guests.js";
import inputPopover from "./components/input-popover.js";
import "./components/gallery.js";
import "./components/datepicker.js";
import "./components/select2.js";
import {FormGuide, FormGuideDefaults} from "./components/form-guide";
import {DeparturePicker} from "./components/departure-picker.js";

$(function() {

    $('#popupCheckModal').length && $('#popupCheckModal').toggleClass('no-modal', functions.getCookie('popupCheck') ? true : false);
    $('.popup-check').length && $('.popup-check').toggleClass('no-modal', functions.getCookie('popupCheck') ? true : false);
    
    let lastScroll = window.scrollY;
    
    $(window).on('scroll', e => {
        $('.link-to-top').toggleClass('show', lastScroll > 300);
        ! functions.getCookie('popupCheck') && ! functions.isMobile() && $('#popupCheckModal').length && ! $('#popupCheckModal').hasClass('no-modal') && $('#popupCheckModal').modal(lastScroll > 500 ? 'show' : 'hide');
        ! functions.getCookie('popupCheck') && functions.isMobile() && $('.popup-check').length && ! $('#popup-check').hasClass('no-modal') && $('.popup-check').toggleClass('show', lastScroll > 500);
        lastScroll = window.scrollY;
    });

    $('.popup-check .close').on('click', e => {
        e.preventDefault();
        e.stopPropagation();
        functions.setCookie('popupCheck', true, 1);
        $('.popup-check').removeClass('show');
        $('.popup-check').addClass('no-modal');
    });
    
    $('#popupCheckModal [data-bs-dismiss="modal"]').on('click', e => {
        functions.setCookie('popupCheck', true, 1);
        $('#popupCheckModal').addClass('no-modal');
        $('.popup-check').addClass('no-modal');
    });

    $('#popupCheckModal').on('show.bs.modal', e => {
        $('.popup-check').addClass('no-modal');
    });

    if($('[data-bs-toggle="tooltip"]').length) {
        $('[data-bs-toggle="tooltip"]').tooltip();
    }

    $('.form-guide').each((i, elem) => {
		new FormGuide(elem);
	});

    $('.input-guests').each((i, elem) => {
        elem._inputGuests = new InputGuests(elem);
    });

    inputPopover.apply();

    $('.datepicker.departure-picker [data-input]').each((i, elem) => {
        elem._departurePicker = new DeparturePicker(elem);
    });

    $('.close-bubble').on('click', e => {
        const $target = $(e.currentTarget);
        const $bubble = $target.parents('.support-bubble');
        $bubble.addClass('closed');
    });

    const $mainHeader = $('.main-header');
    const $navMain = $('.nav-main');

    $('#navMainMenu').on('show.bs.collapse', e => {
        $mainHeader.addClass('opened');
        $navMain.addClass('opened');
    });
    $('#navMainMenu').on('hide.bs.collapse', e => {
        $navMain.removeClass('opened');
    });

    if($('#hero-slider')[0]) {
        new Slider($('#hero-slider'), {
            rubberband: false,
            loop: true,
            arrows: true,
            pager: false,
            //autoplay: 5000,
            slides: {
                perView: 1,
                spacing: 0,
            },
            defaultAnimation: {
                duration: 1200,
            },
            /*detailsChanged: (s) => {
              s.slides.forEach((element, idx) => {
                element.style.opacity = s.track.details.slides[idx].portion
              })
            },*/
            //renderMode: "custom",
        });
    }

    let postSlider = [];
    $('[id^=post-slider]').each((i, elem) => {
        const length = $(elem).find('.keen-slider__slide').length;
        postSlider[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: false,
            arrows: true,
            pager: false,
            slides: {
                perView: 4,
                spacing: 0,
            },
            breakpoints: {
                '(max-width: 768px)': {
                    slides: {
                        perView: 1.2,
                        spacing: 0,
                    },
                },
            }
        });
    });

    let catalogueSlider = [];
    $('[id^=catalogue-slider]').each((i, elem) => {
        const length = $(elem).find('.keen-slider__slide').length;
        catalogueSlider[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: false,
            arrows: true,
            pager: false,
            slides: {
                //origin: "center",
                perView: 3,
                spacing: 0,
            },
            breakpoints: {
                '(max-width: 992px)': {
                    slides: {
                        perView: 2.2,
                        spacing: 0,
                    },
                },
                '(max-width: 576px)': {
                    slides: {
                        perView: 1.1,
                        spacing: 0,
                    },
                },
            }
        });
    });

    $('.open-contact-modal').on('click', e => {
        let $elem = $(e.currentTarget);

        let travelTitle = $elem.find('.card-content-title').text();

        let title = $elem.attr('data-section');

        let $contactModal = $('#contactModal');

        $contactModal.find('.modal-title').text(title);

        $contactModal.find('#travel').val(travelTitle);

        $contactModal.find('#form_title').val(title);
    })

    if(functions.isMobile()) {

        $('.keen-slider-mobile').addClass('keen-slider');
        $('.keen-slider-mobile').removeClass('row');
        $('.keen-slider__slide-mobile').addClass('keen-slider__slide');

        /*let catalogueSlider = [];
        $('[id^=catalogue-slider]').each((i, elem) => {
            const length = $(elem).find('.keen-slider__slide').length;
            catalogueSlider[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: false,
                arrows: true,
                pager: false,
                slides: {
                    //origin: "center",
                    perView: 1.2,
                    spacing: 8,
                }
            });
        });*/

        let navSlider = [];
        $('[id^=nav-slider]').each((i, elem) => {
            const length = $(elem).find('.keen-slider__slide').length;
            navSlider[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: false,
                arrows: true,
                pager: false,
                slides: {
                    perView: 1.6,
                    spacing: 0,
                }
            });
        });

        $('[id^=nav-slider]').find('.nav-link').on('shown.bs.tab', e => {
            const $target = $(e.currentTarget);
            const sliderId = $target.parents('[id^=nav-slider]').attr('id');
            let targetId = $target.attr('data-bs-target').replace('#', '');

            navSlider.forEach(slider => {
                if(sliderId == slider.selector.replace('#', '')) {

                    slider.$slides.each((index, slide) => {
                        if($(slide).find('.nav-link').attr('data-bs-target') == '#' + targetId) {
                            slider.instance.moveToIdx(index);
                        }
                    });

                }
            });

            let $slider = $('.tab-pane[id=' + targetId + ']').find('[id^=catalogue-slider]');

            new Slider($slider, {
                rubberband: false,
                loop: false,
                arrows: true,
                pager: false,
                slides: {
                    //origin: "center",
                    perView: 1.2,
                    spacing: 8,
                }
            });
        });
    }

    $('.search-form label').on('click', e => {
        const $target = $(e.currentTarget);
        const $elem = $target.find('.select2-search__field');
        $elem[0] && $elem.focus();
    });

    $(document).on('click', '[data-scroll-to]', e => {
        e.preventDefault();
        const $this = $(e.target);
        let scrollTo = $this.attr('data-scroll-to').split(',');
        functions.scrollToElement(scrollTo[0], scrollTo[1] || 0);
    });

    $('.newsletter-form').on('submit', function(e) {
        let $elem = this;
        e.preventDefault();
        e.stopPropagation();

        $($elem).find('.contact-btn').addClass('d-none');
        $($elem).find('.contact-spinner').removeClass('d-none');
        $($elem).find('.newsletter-btn').addClass('d-none');

        grecaptcha.ready(function() {
            grecaptcha.execute('6Ldp1DwjAAAAAJTxuC2zNAVkK1DkEFL-Tmp1TLO1', {action: 'newsletter'}).then(function(token) {
                let params = {};
                $.each($($elem).serializeArray(), function (i, field) {
                    params[field.name] = field.value;
                });
                params['language'] = functions.getLang();

                $.post('/services/mailchimp/', params).then(response => {
                    response = JSON.parse(response);

                    $($elem).find(".response-output").removeClass('d-none').html(response.data);
                    $($elem).find('.contact-btn').removeClass('d-none');
                    $($elem).find('.contact-spinner').addClass('d-none');
                    $($elem).find('.newsletter-btn').removeClass('d-none');

                    $elem.reset();
                });
            });
        });
    });


    $('.show-more').each((i, elem) =>
        {
            let $showMore = $(elem);
    
            let height = $showMore.attr('data-height');

            if ($showMore.find('.show-more-content').outerHeight() <= +height)
            {
                $showMore.addClass('removeBefore');
                $showMore.next('.show-more-expand').remove();
                return;
            }
    
            let $breakpoint = $showMore.find('.show-more-breakpoint');
            if (height)
            {
                $showMore.css('max-height', +height);
            }
            else if ($breakpoint.length) 
            {
                $showMore.css('max-height', $breakpoint.offset().top - $showMore.offset().top - 25);
            }
            else 
            {
                let $maxHeight = functions.isMobile() ?
                    ($showMore.outerHeight() * 0.15) :
                    ($showMore.outerHeight() * 0.3);
    
                $showMore.css('max-height', $maxHeight);
            }
    
            $showMore.next('.show-more-expand').on('click', e =>
            {
                let $prevSibling = $(e.currentTarget).prev();
                let $maxHeight
                if ($prevSibling.hasClass('removeBefore'))
                {
                    let height = $prevSibling.attr('data-height');
                    let $breakpoint = $prevSibling.find('.show-more-breakpoint');
                    if (height)
                    {
                        $maxHeight = +height;
                    }
                    else if ($breakpoint.length) 
                    {
                        $maxHeight = $breakpoint.offset().top - $showMore.offset().top - 25;
                    }
                    else 
                    {
                        $maxHeight = functions.isMobile() ?
                            ($prevSibling.outerHeight() * 0.15) :
                            ($prevSibling.outerHeight() * 0.3);
                    }
                    $prevSibling.css('max-height', $maxHeight);
                    $prevSibling.removeClass('removeBefore');
                    if ($prevSibling.hasClass('show-more-scroll')) 
                    {
                        $('html, body').stop().animate({
                            scrollTop: $prevSibling.offset().top + $maxHeight - $(e.target).outerHeight(),
                        }, 0, 'linear');
                    }
                }
                else 
                {
                    let $content = $prevSibling.find('.show-more-content');
                    $prevSibling.css('max-height', $content.outerHeight());
                    $prevSibling.addClass('removeBefore');
                }
            })
        });

});