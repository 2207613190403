if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl
    } = window.wp.components;



    registerBlockType('custom-gutenberg/image-card-3', {
        // built-in attributes
        title: 'Image Card for 3 block',
        description: 'Image Card for 3 block',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            backgroundImage: {
                type: 'string',
                default: ''
            }, 
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            tag: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: 'SAZNAJTE VIŠE'
            },

            isContactModal: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                link,
                backgroundImage,
                title,
                description,
                tag,
                viewMore,

                isContactModal
            } = attributes;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({backgroundImage: image.sizes.full.url});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            function onViewMoreChange(viewMore) {
                setAttributes({viewMore: viewMore});
            }

            function onTagChange(tag) {
                setAttributes({tag: tag});
            }

            function onIsContactModalChange(isContactModal) {
                setAttributes({isContactModal: isContactModal});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Link' }>
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                    </PanelBody>
                    <PanelBody title={ 'Background Image Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ backgroundImage } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                    </PanelBody>
                    <PanelBody title={ 'Slide Content' }>
                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                        <p><strong>Description</strong></p>
                        <TextControl value={ description } onChange={ onDescriptionChange }></TextControl>
                        <p><strong>Tag</strong></p>
                        <TextControl value={ tag } onChange={ onTagChange }></TextControl>
                        <p><strong>Button</strong></p>
                        <TextControl value={ viewMore } onChange={ onViewMoreChange }></TextControl>
                        <br></br>
                        <p><strong>Is contact modal</strong></p>
                        <TextControl value={ isContactModal } onChange={ onIsContactModalChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="keen-slider__slide">
                    <div class="cover-image bg-dark text-white shadow position-relative" style={{ height: 350, backgroundImage: `url(${backgroundImage})` }} >
                        <div class="container d-flex justify-content-center align-items-center h-100">
                            <div class="text-white py-xl-5 py-3 d-flex flex-column align-items-center">
                                <RichText 
                                    key="editable" 
                                    tagName="p" 
                                    class="title"
                                    placeholder="Title" 
                                    value={ title }
                                    onChange={ onTitleChange }
                                    style={ { color: '#fff' } } />
                                <RichText
                                    key="editable"
                                    tagName="div"
                                    class="description"
                                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec ornare sapien eu est sociis phasellus hendrerit quam semper."
                                    value={ description }
                                    onChange={ onDescriptionChange }
                                    style={ { color: '#fff' } } />
                                <RichText
                                    key="editable"
                                    tagName="div"
                                    className=""
                                    placeholder="Najprodavanije"
                                    value={ tag }
                                    onChange={ onTagChange }
                                    style={ { color: '#fff' } } />
                                <RichText
                                    key="editable"
                                    tagName="div"
                                    style={ { fontSize: '15px', display: 'inline-block', backgroundColor: '#E84927', padding: '5px', color: '#fff', marginTop: '1rem' } }
                                    placeholder="SAZNAJTE VIŠE"
                                    value={ viewMore }
                                    style={ { color: '#fff' } } />
                            </div>

                        </div>
                    </div>
                </div>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                backgroundImage,
                title,
                description,
                tag,
                viewMore,

                isContactModal
            } = attributes;

            let elemType = 'a';

            if (isContactModal) elemType = 'div';

            const ElemTag = `` + elemType + ``;

            return (
                <div className="col-12 col-lg position-relative mb-lg-2 mb-3">
                    {(() => {
                        if (tag){
                            return (
                                <div className="card-tag-gold ms-3">
                                    <div>{ tag }</div>
                                </div>
                            )
                        }
                        return null;
                    })()}
                    <ElemTag href={link} className={"position-relative " + (isContactModal ? 'open-contact-modal pointer' : '')}
                        data-bs-toggle={ isContactModal ? 'modal' : null}
                        data-bs-target={ isContactModal ? '#contactModal' : null}
                        data-section={ isContactModal ? isContactModal : null}
                    >
                        <div className="card card-info card-info-3-blocks border-0 position-relative search-card overflow-hidden">
                            <div className="color-overlay-card rounded"></div>
                            <div className="overflow-hidden rounded">
                                <div className="cover-image lozad" data-background-image={ backgroundImage }></div>
                            </div>
                            <div class="card-content position-absolute bottom-0 start-0 px-4 pb-3 text-white">
                                <h4 className="card-content-title text-white mb-0"><span>{ title }</span></h4>
                                <div className="card-content-summary" dangerouslySetInnerHTML={{ __html: description }} />
                                <div className="d-flex align-items-center small mt-1 fw-bold">
                                    { viewMore } <i className="la-fw las la-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                    </ElemTag>
                </div>
            );
        },
    });
}