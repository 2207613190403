import functions from '../functions';
import { list } from './list';

$(() => {
    listTravel.init();
});

const listTravel = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {
        _page: 1,
        perPage: 12,
        language: functions.getLang(),
        arrivalDateFrom: '',
        destination: '',
        countryId: '',
        objectGroupId: 6,
        guests: '',
        hasArrival: 2,
        transportationType: null,
    },
    params: {},
    isHome: false,
    $listItems: null,
    /*filterParams: {
        tagCategoryId: null,
        sortBy: null, 
        objectName: null,
    },*/
    mainFields: ['arrivalDateFrom', 'destination', 'sortBy', '_page'],
    init() {
        this.$form = $('.search-form.search-travel');
        this.form = this.$form[0];

        if(! this.form) {
            return;
        }

        this.$listItems = $('.list-items');
        this.isHome = this.$form.last().attr('data-ishome') || ! this.$listItems.length;

        this.listURL = this.$form.last().attr('data-listurl');
        this.defaults.objectGroupId = this.$form.last().attr('data-objectgroupid');
        this.defaults.objectTypeId = this.$form.last().attr('data-objecttypeid');
        this.defaults.transportationType = this.$form.last().attr('data-transportationType');

        this.defaults.countryId = this.$form.last().attr('data-countryid');
        this.defaults.destination = this.$form.last().attr('data-destination');
        this.defaults.destination = this.defaults.destination ? this.defaults.destination : this.defaults.countryId;
        //this.defaults.countryId = this.defaults.destination = this.$form.last().attr('data-countryid');

        this.defaults.tagCategoryId = this.$form.last().attr('data-tagcategoryid');
        
        this.$form.on('submit', e => {
            const $target = $(e.target);
            e.preventDefault();

            this.isHome = $target.attr('data-ishome') || this.isHome;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                this.params.transportationType = $target.attr('data-transportationType');

                this.params.countryId = $target.attr('data-countryid');
                this.params.destination = $target.attr('data-destination');
                this.params.destination = this.params.destination ? this.params.destination : this.params.countryId;

                this.params.perPage = $target.attr('data-perpage') || this.defaults.perPage;
            }

            this.params = $.extend({}, this.defaults, functions.getFormData($target));
            this.params._page = this.defaults._page;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                functions.removeNull(this.params);
                location = $target.attr('data-listurl') + '#' + $.param(this.params);
            } else {
                functions.setUrlData(this.params, true, true);
            }

        });

        if(! this.isHome) {

            this.getParams();           
            functions.setFormData(this.form, this.params);
            this.params.guests = $('[name=guests]').val();
            this.find(this.params);
        } else {
            functions.setFormData(this.form, this.defaults);
        }

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.params.destination = '';
            $(this.form.destination).val('').trigger('change');
        });
        

        if(this.params.objectGroupId == 6) {
            $('[name="tagCategoryId"]').on('change', e => {
                this.params._page = this.defaults._page;
                this.getParams();
                this.setTagCategoryId();
                this.find(this.params);
            });
        }

        $(window).on('hashchange', e => {
            this.getParams();
            this.setTagCategoryId();
            this.stopChangeEvents = true;

            functions.scrollToElement(this.$listItems, 100);
            this.find(this.params);
            this.stopChangeEvents = false;
        });
    },
    getParams() {      
        this.params = list.getParams(this.params, this.defaults);
        //this.params = list.getParams(this.params, this.defaults, this.filterParams);
    },
    setTagCategoryId() {
        if($('[name="tagCategoryId"]').length) {
            let tagCategoryIds = [];
            $('[name="tagCategoryId"]').each((index, target) => {
                const $target = $(target);
                if($target[0].checked) {
                    tagCategoryIds.push($target.attr('data-value'));
                }
            });
            this.params.tagCategoryId = tagCategoryIds.join(",");
        }
    },
    find(params) {
        list.find(params).then((response) => {
            list.findDone(response);
            // LOZAD
            window.observer.observe();
        });
    },

};