import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        InnerBlocks,
    } = window.wp.editor;

    registerBlockType('custom-gutenberg/container', {
        // built-in attributes
        title: 'Container',
        description: 'Custom Container',
        icon: '',
        category: 'custom-containers',
        example: {},

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const MY_TEMPLATE = [];
            const {
                classes
            } = attributes

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className})

            return (
                <div class={ "container " + className }>
                    <InnerBlocks template={ MY_TEMPLATE } />
                </div>
            );
        },

        save({ attributes }

        ) {

            const {
                classes
            } = attributes;

            return (
                <div className={ "container " + (classes || '') }>
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}