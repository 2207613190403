import './blocks/containers/container';
import './blocks/containers/section-with-pattern';
//import './blocks/cards/cards-4-images.js';

import './blocks/hero-slider.js';
import './blocks/hero-slide.js';

import './blocks/image-cards-3.js';
import './blocks/image-card-3.js';

import './blocks/image-cards-4.js';
import './blocks/image-card-4.js';

import './blocks/containers/row.js';
import './blocks/containers/column.js';