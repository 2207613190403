import functions from '../functions.js';

class DetailsCart {
    cartItems = [];
    total = 0;
    totalOld = 0;
    currencySymbol = '€';
    $cartModal = $('#detailsCartModal');
    $cartItems = $('.cart-items');
    $itemCount = $('.cart-items-count');
    $addToCart = $('.add-to-cart');
    $priceContainer = $('.cart-price');
    $priceTotal = $('.cart-price-total');
    $priceTotalOld = $('.cart-price-old');

    constructor(params) {
        this.$addToCart.on('click', e => {
            const $target = $(e.currentTarget);

            params.unitId = $target.attr('data-unitid');
            params.unitTitle = $target.attr('data-unittitle');
            params.serviceName = $target.attr('data-servicename');
            params.unitCartTotal = +$target.attr('data-unitcarttotal');
            params.unitCartTotalOld = +$target.attr('data-unitcarttotalold');
            params.dateFrom = $target.attr('data-datefrom');
            params.dateTo = $target.attr('data-dateto');
            params.guests = $target.attr('data-guests');
            params.flightUnitId = $target.attr('data-flightunitid');
            params.time = $target.attr('data-time');
            params.serviceCode = $target.attr('data-servicecode');
            this.currencySymbol = $target.attr('data-currencysymbol');

            this.addToCart(params);
            this.displayCartItems(this.cartItems);
            this.removeCartItem(null);

            this.$cartModal.modal('show');
            setTimeout(() => {
                this.$cartModal.modal('hide');
            }, 2500);
        });

        this.displayCartItems();

    }

    addToCart(params) {

        this.cartItems.push(JSON.stringify({
            'unitId': params.unitId,
            'unitTitle': params.unitTitle,
            'serviceName': params.serviceName,
            'dateFrom': params.dateFrom,
            'dateTo': params.dateTo,
            'guests': params.guests,
            'optionals': params.optionals,
            'pricelistId': params.pricelistId,
            'unitCartTotal': params.unitCartTotal,
            'unitCartTotalOld': params.unitCartTotalOld,
            'flightUnitId': params.flightUnitId,
            'time': params.time,
            'serviceCode': params.serviceCode,
        }));

    }

    removeCartItem(index) {

        this.cartItems = this.cartItems.filter((item, i) => {
            return i != index;
        });
    }

    getCartItemsCount() {
        return this.cartItems.length;
    }

    displayCartItems() {

        let html = '';
        
        this.cartItems.forEach((item, index) => {
            item = JSON.parse(item);
            html += `<div class="cart-item" >
                <div class="cart-item-title">${ item.unitTitle }</div>
                <div class="cart-item-dates"><i class="las la-calendar la-lg"></i>${ functions.formatDate(item.dateFrom) + ' - ' + functions.formatDate(item.dateTo) }</div>
                <div class="cart-item-data">${ item.serviceName ? item.serviceName + ', ' : '' }${ this.displayGuests(item.guests) }</div>
                <i class="cart-remove-item las la-times-circle la-lg" data-unitid="${ item.unitId }" data-datefrom="${ item.dateFrom }" data-dateto="${ item.dateTo }" data-index="${ index }"></i>
            </div>`;
        });

        if(! this.getCartItemsCount()) {
            html = '<div class="cart-empty">Vaša košarica je prazna!</div>';
        }

        this.$cartItems.html(html);
        this.$itemCount.html(this.getCartItemsCount());
        this.$priceContainer.toggleClass('d-none', !this.getCartItemsCount());
        this.setCartTotal();

        $('.cart-remove-item').on('click', e => {
            const $target = $(e.currentTarget);
            const index = $target.attr('data-index');

            this.removeCartItem(index);
            this.displayCartItems();
        });

    }

    displayGuests(guests) {
        if(guests) {
            guests = guests.split(',');
            let adults = guests[0];
            let children = guests[1];
            return adults + ' odrasla' + (+children ? ' i ' + children + ' dece' : '');
        }

        return guests;
    }

    setCartTotal() {
        this.total = this.totalOld = 0;
        if(this.getCartItemsCount()) {
            this.cartItems.filter(item => {
                item = JSON.parse(item);
                this.total += item.unitCartTotal;
                this.totalOld += item.unitCartTotalOld;
            });
        }
        this.$priceTotal.html(this.total ? this.currencySymbol + ' ' + functions.formatMoney(this.total, 0, '.', ',') : '--');
        //this.$priceTotalOld.html(this.totalOld);
    }
}

export {
    DetailsCart
};