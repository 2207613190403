import functions from "./functions.js";

$(function () {

    const $form = $('.booking-add-guests');
    const form = $form[0];

    if (!form) {
        return;
    }

    const bookingAddGuests = {
        $form: null,
        $payment: null,
        $calculation: null,
        calc: [],
        persons: null,
        promoDiscount: null,
        totalAmount: null,
        totalAmountConverted: null,
        insurance: null,
        status: null,

        init() {

            bookingAddGuests.$promoCodeSuccess = $(".promoCodeSuccess");
            bookingAddGuests.$promoCodeError = $(".promoCodeError");
            bookingAddGuests.$calculation = $(".calculation");

            $("#customerIsPassenger").on('change', e => {
                let $passenger = $('.passenger:first').closest('.row');
                $passenger.find('[name="firstName0"]').val($('.customer[name="firstName"]').val());
                $passenger.find('[name="lastName0"]').val($('.customer[name="lastName"]').val());
                $passenger.find('[name="email0"]').val($('.customer[name="email"]').val());
                $passenger.find('[name="phone0"]').val($('.customer[name="phone"]').val());
                $passenger.find('[name="address0"]').val($('.customer[name="address"]').val());
                $passenger.find('[name="city0"]').val($('.customer[name="city"]').val());
            });

            $('#promoCodeApply').on('click', function (e) {
                e.preventDefault();

                let params = functions.getUrlData(false);
                let code = $("[name='promoCode']").val();
                let rId = $("[name='rId']").val();
                let rhid = $("[name='rhid']").val();

                params.language = functions.getLang();
                params.promoCode = code;
                params.rId = rId;
                params.rhid = rhid;

                bookingAddGuests.$promoCodeSuccess.addClass('d-none');
                bookingAddGuests.$promoCodeError.addClass('d-none');

                $.get('/services/booking_add_guests/', $.param(params)).then(response => {
                    response = JSON.parse(response);
                    if (response.status) {
                        bookingAddGuests.$calculation.html($(response.html).find('.calculation').html());
                        bookingAddGuests.$promoCodeSuccess.removeClass('d-none');
                        bookingAddGuests.$promoCodeError.addClass('d-none');
                    } else {
                        bookingAddGuests.$promoCodeSuccess.addClass('d-none');
                        bookingAddGuests.$promoCodeError.removeClass('d-none');
                    }
                });
            });

            $form.on('submit', e => {
                e.preventDefault();
                bookingAddGuests.submit();
            });

        },
        submit() {

            let data = {
                customer: {},
                passengers: []
            };
            $(".customer").each(function( index ) {
                data.customer[$(this).attr('name')] = $(this).val();
            });

            $(".passenger").each(function( index ) {
                let $input = $(this).attr('name');
                let idx = $input.replace(/\D/g,'');
                let name = $input.replace(/[0-9]/g, '');

                if ( ! data.passengers[idx]) data.passengers[idx] = {};
                data.passengers[idx][name] = $(this).val();
            });

            $(".placeFrom").each(function( index ) {
                let $input = $(this).attr('name');
                let idx = $input.replace(/\D/g,'');

                if ( ! data.placeFrom) data.placeFrom = {};
                data.placeFrom[idx] = $(this).val();
            });

            data.rId = $("[name='rId']").val();
            data.rhid = $("[name='rhid']").val();

            if ($("[name='agreeContact']").attr('checked')) {
                data.note = 'Putnici se slažu da se kontakt podaci koriste za marketinške svrhe';
            }


            $.post('/services/booking_add_guests/', data).then(response => {

                response = JSON.parse(response);

                $('#bookingAddGuests').modal('show');
                $(".response-output-success").toggleClass('d-none', !response.status);
                $(".response-output-error").toggleClass('d-none', response.status);

                $('.contact-btn').removeClass('d-none');
                $('.contact-spinner').removeClass('d-flex');
                $('.contact-spinner').addClass('d-none');

                setTimeout(() => {
                    $('#bookingAddGuests').modal('hide');
                    // details contact modal
                    $('#bookingAddGuests').modal('hide');
                }, 4000);
            });

        },
    }

    bookingAddGuests.init();
});