const flatpickr = require("flatpickr");
const functions = require("../functions.js");
const dNone = 'd-none';
const monthsLong = flatpickr.default.l10ns[functions.getLang()].months.longhand;
const monthsShort = flatpickr.default.l10ns[functions.getLang()].months.shorthand;
const curDate = new Date;
const curMonth = curDate.getMonth();
let curYear = curDate.getFullYear();
let newYear = curYear;

// APR, MAY, JUN, JUL, AUG, SEP, OCT
//const availMonths = [3, 4, 5, 6, 7, 8, 9];

const extractMonth = short => {
    let index = monthsShort.indexOf(short);
    let year = curYear;
    let month = index + 1;
    month = month > 9 ? month : '0' + month;
    // add year when we pass 12    
    if ( newYear == curYear && index == monthsShort.length - 1 ) {
        curYear++;
        newYear++;
    }
    return {
        value: year + '-' + month + '-01',
        year: year,
        short: short,
        long: monthsLong[index]
    };
    /*if(availMonths.includes(index)) {
    }*/
};
const buildMonths = () => {
    let curY = curDate.getFullYear();
    let newY = curDate.getFullYear();
    return months.map(month => {
        curY = month.year;
        let html = (curY != newY ? `<hr class="w-100 mt-2 mb-1 color-primary">` : ``) +
            `<div class="col-4 mt-2">
                <button class="btn btn-light text-dark py-2 fw-bold w-100" data-value="${month.value}">
                    ${month.short}<div class="small fw-bold text-primary">${month.year}</div>
                </button>
            </div>
        `;
        newY = curY;
        return html;
    }).join('');
};

/*
    Populate months
    1. rest of this year
    2. whole new year
    3. what is left till we reach 20 months
*/

let months = monthsShort.slice(curMonth).map(extractMonth).concat(monthsShort.map(extractMonth));

// filter available months
months = months.filter(item => item);
//months = months.filter(item => item.year == curDate.getFullYear());

//let moreMonths = months.length - 12;
//moreMonths > 0 && (months = months.concat(monthsShort.slice(0, moreMonths).map(extractMonth)));
//moreMonths < 0 && (months = months.slice(0, 12));
months = months.slice(0, 12);


const init = elem => {
    const instance = {
        elem: elem,
        $elem: $(elem)
    };
   
    instance.$value = instance.$elem.find('.input-popover-value');
    instance.placeholder = instance.$value.attr('data-placeholder');
    instance.$dropdown = instance.$elem.closest('.dropdown');
    instance.$menu = instance.$dropdown.find('.dropdown-menu-popover');
    instance.$hidden = instance.$dropdown.find('input[type="hidden"]');
    instance.$removeValue = instance.$elem.find('.remove-popover-value');
    !instance.value && instance.$removeValue.addClass('d-none');

    // populate dropdown
    instance.$menu.html(`<div class="row gx-2">${buildMonths()}</div>`);

    // events
    // open
    instance.$dropdown.on('show.bs.dropdown', e => {
        instance.$menu.find('.active').removeClass('active');
        instance.$menu.find('.btn[data-value="' + instance.value + '"]').addClass('active');
    });

    instance.$removeValue.on('click', e => {
        e.stopPropagation();
        // hide x btn
        $(e.currentTarget).addClass('d-none');
        // "unselect" the month
        instance.$menu.find('.btn[data-value="' + instance.value + '"]').removeClass('active');
        // actually remove value
        instance.$hidden[0].value = "";
        elemChange(instance);
    });
    
    // button click element and trigger change
    instance.$menu.on('click', 'button', e => {
        e.preventDefault();
        instance.$hidden[0].value = $(e.currentTarget).attr('data-value');
        instance.$hidden.trigger('change');
        instance.$elem.dropdown('hide');
    });
    // elem change
    instance.$hidden.on('change', e => elemChange(instance));    
    // init set
    elemChange(instance);
};

const elemChange = instance => {
    instance.value = instance.$hidden[0].value || null;
    instance.value && instance.$removeValue.removeClass('d-none');
    let selectedMonth = months.find(month => {
        return month.value == instance.value;
    });
    instance.$value.text(selectedMonth ? selectedMonth.long + ' ' + selectedMonth.year : instance.placeholder);
};

module.exports = {
    init: init,
    apply() {
        $('.input-popover').each((i, elem) => {
            init(elem);
        });
    }
};