import functions from '../functions.js';
import Slider from "../components/Slider";
import { details } from './details';

$(() => {
    detailsPackage.init();
})

const detailsPackage = {
    $details: null,
    $form: null,
    form: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    init() {

        this.$details = $('.page-type-details-package');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');

        if(! this.$details[0] && this.params.objectGroupId != 1) {
            return;
        }

        this.params.dynamicPackage = 1;
        this.params.objectId = this.$details.attr('data-objectid');
        this.getParams();

        this.initDepartureCalendar();
        this.setDefaultDeparture();

        const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']');
        this.showFlightTickets($departure);
        this.setPrices($departure);

        $('.departure').on('click', e => {
            $('.departure').removeClass('active');

            const $target = $(e.currentTarget);

            this.params.dateFrom = $target.attr('data-datefrom');
            this.params.dateTo = $target.attr('data-dateto');

            $target.addClass('active');

            this.showFlightTickets($target);
            this.setPrices($target);
            this.printDates();

            functions.setUrlData({'dateFrom': this.params.dateFrom, 'dateTo': this.params.dateTo, 'guests': this.params.guests }, true);

        });

        $('[name=guests]').on('change', e => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            this.params.unitId = $target.attr('data-unitid');
            this.params.guests = $target.val();
            this.getCalculation();
        });

        //this.getCalculation();

        $(window).on('hashchange', e => {
            this.getParams();

            $('.unit').each((index, unit) => {
                const $unit = $(unit);
                this.params.unitId = $unit.attr('data-unitid');
                this.params.guests = $unit.find('[name=guests]').val();
                this.getCalculation();
            });
        });

        $('.btn-booking').on('click', e => {

            const $target = $(e.currentTarget);
            if ( ! $target.attr('href')) {
                e.preventDefault();
                setTimeout(() => {
                    $target.closest('.unit').find('.input-guests').first().trigger('click');
                });
            }
        });

    },
    setPrices($target) {

        if(this.params.dateFrom) {
            this.loader();

            const $units = $('.unit[data-unitid]');

            $units.find('.unit-price-old').text('');
            $units.find('.unit-price-total').text('');
            $units.find('.unit-price-total-label').addClass('d-none');
            $units.find('.unit-price-total').addClass('d-none');
            $units.find('.unit-price-old').addClass('d-none');

            let prices = JSON.parse($target.attr('data-prices'));

            setTimeout(() => {
                Object.entries(prices).forEach(item => {
                    const unitId = item[0];
                    const rate = item[1];

                    const $unit = $('.unit[data-unitid="' + unitId + '"]');
                    let guests = $unit.find('[name=guests]').val();

                    if (!guests || guests == '0,0') {

                        $unit.find('.unit-price').removeClass('d-none');
                        $unit.find('.unit-price-total').removeClass('d-none');

                        $unit.find('.unit-price-old').toggleClass('d-none', !rate.priceWithDiscount);
                        $unit.find('.unit-price-old').text(rate.priceWithDiscount ? rate.price : '');
                        $unit.find('.unit-price-total').text(rate.priceWithDiscount ? rate.priceWithDiscount : rate.price);

                        $unit.find('.fm-discount').toggleClass('d-none', !rate.discountTitle);
                        $unit.find('.fm-discount-title').text(rate.discountTitle ? rate.discountTitle : '');
                        $unit.find('.fm-discount-valid-until').toggleClass('d-none', !rate.validUntil);
                        $unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');

                        $unit.find('.unit-price-total').toggleClass('text-danger', rate.priceWithDiscount ? true : false);
                    } else {
                        if ($unit.find('.unit-price-old').text()) $unit.find('.unit-price-old').removeClass('d-none');
                        $unit.find('.unit-price-total-label').removeClass('d-none');
                        $unit.find('.unit-price-total').removeClass('d-none');
                    }

                    $unit.removeClass('d-none').removeClass('no-price');
                });
            }, 1000);

        }
    },
    showFlightTickets($target) {

        $('.flight-ticket').addClass('d-none');

        let dateFrom = $target.attr('data-dateFrom');
        let dateTo = $target.attr('data-dateTo');

        const $tickets = $('.flight-ticket[data-datefrom="' + dateFrom + '"][data-dateto="' + dateTo + '"]');
        $tickets.removeClass('d-none');

        // set active ticket
        this.setFlightTicket($tickets.first());
    },
    setFlightTicket($tickets = null) {
        $('.flight-ticket').removeClass('active');
        if($tickets) {
            $tickets.addClass('active')
            this.setUnitRateIds($tickets);
        }

        $('.flight-ticket').on('click', e => {
            $('.flight-ticket').removeClass('active');
            const $target = $(e.currentTarget);
            $target.addClass('active');

            this.setUnitRateIds($target);
        });
    },
    setUnitRateIds($target) {
        const rateIds = JSON.parse($target.attr('data-rateids'));
        Object.entries(rateIds).forEach(item => {
            const unitId = item[0];
            const rateId = item[1];

            $('.btn-booking[data-unitid=' + unitId + ']').attr('data-rateid', rateId);
        })
    },
    initDepartureCalendar() {
        const $bookingCalendar = $('#booking-calendar-slider');

        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 6;
            let slidesPerView = length < perView ? length : perView;

            this.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: functions.isMobile() || length > slidesPerView,
                slides: {
                    perView: functions.isMobile() ? 2 : perView,
                    spacing: 0,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 6,
                        //centered: true,
                        slides: {
                            perView: 6.2,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.5,
                        },
                    },
                },
            });
        }
    },
    setDefaultDeparture() {
        this.params.dateFrom = ! this.params.dateFrom ? $('[data-datefrom]').attr('data-datefrom') : this.params.dateFrom;
        $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
        this.params.dateTo = $('[data-datefrom=' + this.params.dateFrom + ']').attr('data-dateto');

        this.params.guests = $('[name=guests]').val(); 

        functions.setUrlData({'dateFrom': this.params.dateFrom }, true);

        this.printDates();
    },
    printDates() {
        this.params.dateFrom && $('.date-from').text(functions.formatDate(this.params.dateFrom));
        this.params.dateTo && $('.date-to').text(functions.formatDate(this.params.dateTo));
    },
    getParams() {
        this.params = details.getParams(this.params);
    },
    getCalculation() {
        details.getCalculation(this.params)
            .done(response => {
                this.calculationDone(response);
            }).fail(error => {
                //console.log(error);
            });
    },
    loader($target = null) {
        if ($target) {
            $target.addClass('active');
        } else {
            $('.unit-spinner-block').removeClass('d-none');
            $('.unit-spinner').addClass('active');
        }

        setTimeout(() => {
            if ($target) {
                $target.removeClass('active');
            } else {
                $('.unit-spinner-block').addClass('d-none');
                $('.unit-spinner').removeClass('active');
            }
        }, 800);
    },
    calculationDone(response) {
        if(response.status) {
            const data = response.data || [];

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');

                if(details.isBookable(unitData)) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);

                    if(+priceOld.toFixed(2) > +total.toFixed(2)) {
                        $unit.find('.unit-price-old').removeClass('d-none');
                        $unit.find('.unit-price-old').text(currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ','));
                        $unit.find('.unit-price-total').addClass('text-danger');
                    } else {
                        $unit.find('.unit-price-old').html('');
                        $unit.find('.unit-price-old').addClass('d-none');
                        $unit.find('.unit-price-total').removeClass('text-danger');
                    }

                    $unit.find('.btn-booking').removeClass('d-none');
                    $unit.find('.btn-soldOut').addClass('d-none');
                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));

                    $unit.find('.btn-booking').attr('href', $unit.find('.btn-booking').attr('data-href'));

                    if($unit.find('.btn-booking').attr('href')) {
                        $unit.find('.btn-booking').attr('href',
                            $unit.find('.btn-booking').attr('href').split('?')[0] +
                            '?unitId=' + unitId +
                            '&dateFrom=' + this.params.dateFrom +
                            '&dateTo=' + this.params.dateTo +
                            '&guests=' + $unit.find('[name=guests]').val() +
                            '&addAllOptionals=' + 1 +
                            (this.params.partnerId ? '&partnerId=' + this.params.partnerId : '') +
                            (this.params.dynamicPackage ? '&dynamicPackage=' + this.params.dynamicPackage : '') + 
                            (this.params.objectGroupId ? '&objectGroupId=' + this.params.objectGroupId : '')
                        );
                    }

                    this.printDates();

                    if ($unit.find('.unit-spinner.active').length) {
                        $unit.find('.unit-price-total-label').addClass('d-none');
                        $unit.find('.unit-price-total').addClass('d-none');
                        $unit.find('.unit-price-old').addClass('d-none');
                    }
                } else {
                    $unit.find('.btn-booking').attr('href', '');
                    $unit.find('.btn-booking').addClass('d-none');
                    $unit.find('.unit-price-total-label').addClass('d-none');
                    $unit.find('.unit-price-total').addClass('d-none');
                    $unit.find('.btn-soldOut').removeClass('d-none');
                }
            });
        }
    }
};
