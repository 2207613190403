if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        InnerBlocks,
    } = window.wp.editor;

    registerBlockType('custom-gutenberg/section-with-pattern', {
        // built-in attributes
        title: 'Section with pattern',
        description: 'Custom Section',
        icon: '',
        category: 'custom-containers',
        example: {},

        // built-in functions
        edit() {

            const MY_TEMPLATE = [];

            return (
                <div class="container my-3 my-lg-5">
                    <InnerBlocks template={ MY_TEMPLATE } />
                </div>
            );
        },

        save() {

            return (
                <section className="bg-pattern pb-5">
                    <InnerBlocks.Content />
                </section>
            );
        },
    });
}