import functions from '../functions.js';
import Slider from "../components/Slider";
import { details } from './details';
import { DetailsCart } from './details-cart.js';

$(() => {
    detailsAccommodation.init();
})

const detailsAccommodation = {
    $details: null,
    $form: null,
    form: null,
    $cart: null,
    cart: null,
    availability: {},
    params: {
        idList: null,
        objectId: null,
        unitId: null,
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null,
        objectGroupId: null,
        pricelistId: null,
        calcErrorMessages: null,
    },
    init() {

        this.$details = $('.page-type-details-accommodation');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');

        if(! this.$details[0] && this.params.objectGroupId != 1) {
            return;
        }

        detailsAccommodation.$form = $('.availability-form');
        this.params.objectId = this.$details.attr('data-objectid');

        this.getParams();

        if (this.params.dateFrom && this.params.dateTo) this.params.dates = this.params.dateFrom + ' - ' + this.params.dateTo;

        functions.setFormData(detailsAccommodation.$form[0], this.params);
        this.getCalculation();

        detailsAccommodation.$form.on('submit', e => {
            e.preventDefault();

            let params = functions.getFormData($(e.currentTarget));
            functions.setUrlData(params, true);
        });

        $('[name=guests], [name="serviceCode"], [name="optionals"], [name="pricelistId"]').on('change', e => {
            let params = functions.getFormData(this.$form);
            functions.setUrlData(params, true, true);
        });

        $('[name=dates]').on('change', e => {
            let params = functions.getFormData(this.$form);
            if (params.dates.indexOf(' - ') !== -1) {
                functions.setUrlData(params, true, true);
            }
        });

        $(window).on('hashchange', e => {
            this.getParams();
            if (detailsAccommodation.params.dateFrom && detailsAccommodation.params.dateTo && detailsAccommodation.params.guests != '0,0') {
                $(".spinner-border").removeClass('d-none')
            }
            this.getCalculation();
        });

        this.initCart();

    },

    initCart() {

        this.$cart = $('.details-cart');
        this.cart = new DetailsCart(this.params);
        this.cart.removeCartItem = (index) => {
            if (index) {
                let cartItem = this.cart.cartItems.filter((item, i) => {
                    return i == index;
                });

                cartItem = JSON.parse(cartItem[0]);

                let quantity = this.getAvailableQuantity(cartItem.unitId, cartItem.dateFrom, cartItem.dateTo) + 1;
                this.updateAvailableQuantity(cartItem.unitId, cartItem.dateFrom, cartItem.dateTo, quantity);

                if (quantity && cartItem.dateFrom == detailsAccommodation.params.dateFrom && cartItem.dateTo == detailsAccommodation.params.dateTo) {
                    let $unit = $('.unit[data-unitid="'+cartItem.unitId+'"]');
                    $unit.find('.add-to-cart-custom').removeClass('d-none');
                    $unit.find('.btn-soldOut').addClass('d-none');
                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').removeClass('d-none');
                }
            }

            DetailsCart.prototype.removeCartItem.call(this.cart, index);
        }

        $('.btn-booking').on('click', e => {
            e.preventDefault();
            if ( !this.cart.cartItems.length ) {
                $('#booking')[0].scrollIntoView();
            } else {
                let cartData = [];

                this.cart.cartItems.forEach(item => {
                    item = JSON.parse(item);
                    cartData.push({
                        'unitId': item.unitId,
                        'dateFrom': item.dateFrom,
                        'dateTo': item.dateTo,
                        'guests': item.guests,
                    });
                });

                let url = $(e.currentTarget).attr('data-href') + '?cart_data=' + JSON.stringify(cartData) + '&addAllOptionals=true';

                if(this.cart.cartItems.length) {
                    location = url;
                } else {
                    $('#detailsCartModal').modal('show');
                    setTimeout(() => {
                        $('#detailsCartModal').modal('hide');
                    }, 2500);
                }
            }
        });

        $('.add-to-cart-custom').on('click', e => {

            e.preventDefault();
            const $target = $(e.currentTarget);
            let unitId = $target.attr('data-unitid');

            let quantity = this.getAvailableQuantity(unitId, detailsAccommodation.params.dateFrom, detailsAccommodation.params.dateTo);

            quantity = quantity - 1;
            this.updateAvailableQuantity(unitId, detailsAccommodation.params.dateFrom, detailsAccommodation.params.dateTo, quantity);

            let params = {};
            params.unitId = $target.attr('data-unitid');
            params.unitTitle = $target.attr('data-unittitle');
            params.unitCartTotal = +$target.attr('data-unitcarttotal');
            params.unitCartTotalOld = +$target.attr('data-unitcarttotalold');
            params.dateFrom = this.params.dateFrom;
            params.dateTo = this.params.dateTo;
            params.guests = this.params.guests;
            this.cart.currencySymbol = '€';

            this.cart.addToCart(params);
            this.cart.displayCartItems(this.cartItems);
            this.cart.removeCartItem(null);

            $('#detailsCartModal').modal('show');
            setTimeout(() => {
                $('#detailsCartModal').modal('hide');
            }, 2500);

            if ( ! quantity)
            {
                let $unit = $('.unit[data-unitid="'+unitId+'"]');
                $unit.find('.add-to-cart-custom').addClass('d-none');
                $unit.find('.unit-price-total-label').addClass('d-none');
                $unit.find('.unit-price-total').addClass('d-none');
                $unit.find('.unit-price-old').addClass('d-none');
                $unit.find('.btn-soldOut').removeClass('d-none');
            }
        });
    },

    getAvailableQuantity(unitId, dateFrom, dateTo) {
        return detailsAccommodation.availability[unitId + "_" + dateFrom + "_" + dateTo];
    },

    updateAvailableQuantity(unitId, dateFrom, dateTo, quantity) {
        detailsAccommodation.availability[unitId + "_" + dateFrom + "_" + dateTo] = quantity;
    },

    printDates() {
        $('.date-from').text(functions.formatDate(this.params.dateFrom));
        $('.date-to').text(functions.formatDate(this.params.dateTo));
    },
    getParams() {

        this.params = details.getParams(this.params);
        detailsAccommodation.splitCalendarDates(this.params.dates);
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    getCalculation() {

        details.getCalculation(this.params)

            .done(response => {
                this.calculationDone(response);
                $(".spinner-border").addClass('d-none')
            }).fail(error => {
                //console.log(error);
            });
    },
    loader($target = null) {
        if ($target) {
            $target.addClass('active');
        } else {
            $('.unit-spinner-block').removeClass('d-none');
            $('.unit-spinner').addClass('active');
        }

        setTimeout(() => {
            if ($target) {
                $target.removeClass('active');
            } else {
                $('.unit-spinner-block').addClass('d-none');
                $('.unit-spinner').removeClass('active');
            }
        }, 800);
    },
    isBookable(data) {
        return data.booking.status == 'Reservation' && data.isAvailable && data.calc.status == 'Active';
    },
    calculationDone(response) {
        if(response.status) {
            const data = response.data || [];

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');

                let quantity = unitData ? +unitData.quantity : 0;
                let available = this.getAvailableQuantity(unitId, detailsAccommodation.params.dateFrom, detailsAccommodation.params.dateTo);

                if ( ! available && available !== 0) {
                    this.updateAvailableQuantity(unitId, detailsAccommodation.params.dateFrom, detailsAccommodation.params.dateTo, quantity);
                }
                else {
                    quantity = available;
                }

                $unit.find('.unit-price-label').addClass('d-none');

                if(this.isBookable(unitData) && quantity) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);

                    if(+priceOld.toFixed(2) > +total.toFixed(2)) {
                        $unit.find('.unit-price-old').removeClass('d-none');
                        $unit.find('.unit-price-old').text(currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ','));
                        $unit.find('.add-to-cart-custom').attr('data-unitcarttotalold', priceOld);
                    } else {
                        $unit.find('.unit-price-old').html('');
                        $unit.find('.unit-price-old').addClass('d-none');
                    }

                    $unit.find('.add-to-cart-custom').removeClass('d-none');
                    $unit.find('.btn-soldOut').addClass('d-none');
                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));
                    $unit.find('.add-to-cart-custom').attr('data-unitcarttotal', total);

                    this.printDates();

                    if ($unit.find('.unit-spinner.active').length) {
                        $unit.find('.unit-price-total-label').addClass('d-none');
                        $unit.find('.unit-price-total').addClass('d-none');
                        $unit.find('.unit-price-old').addClass('d-none');
                    }
                } else {
                    $unit.find('.add-to-cart-custom').addClass('d-none');
                    $unit.find('.unit-price-total-label').addClass('d-none');
                    $unit.find('.unit-price-total').addClass('d-none');
                    $unit.find('.unit-price-old').addClass('d-none');
                    $unit.find('.btn-soldOut').removeClass('d-none');
                }
            });
        }
    }
};
