import _default from "keen-slider";

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        InnerBlocks
    } = window.wp.editor;

    const ALLOWED_BLOCKS = ['custom-gutenberg/image-card-4'];

    registerBlockType('custom-gutenberg/image-cards-4', {
        // built-in attributes
        title: 'Image cards with 4 blocks',
        description: 'Custom Image cards',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // built-in functions
        edit() {

            const MY_TEMPLATE = [
                [ 'custom-gutenberg/image-card-4', {} ],
            ];


            return (
                <div>
                    <InnerBlocks allowedBlocks={ ALLOWED_BLOCKS } template={ MY_TEMPLATE } />
                </div>
            );
        },

        save() {

            return (
                <div className="card-info-4-blocks row gx-2 position-relative">
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}