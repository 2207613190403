import functions from '../functions';

$(() => {
    details.init();
})

const details = {
    $details: null,
    $form: null,
    form: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    init() {

        details.$details = $('.page-type-details');

        if(! details.$details[0]) {
            return;
        }

    },
    getParams(params) {
        //console.log(functions.getUrlData(true));
        return $.extend({}, params, functions.getUrlData(true));
    },
    isBookable(data, rateId = null) {
        if(rateId) {
            return (data.booking.status == 'Request' || data.booking.status == 'Reservation') && data.isAvailable && data.calc.status == 'Active';
        }
        return data.booking.status == 'Request' || data.booking.status == 'Reservation';
    },
    getCalcErrorMessage(error) {
        if (error && details.calcErrorMessages && details.calcErrorMessages[error.type]) {
            return details.calcErrorMessages[error.type].replace('{$condition}', error.condition);
        }
        return details.calcErrorMessages ? details.calcErrorMessages.general : null;
    },
    getCalculation(params) {

        const objectGroupId = params.objectGroupId;
        const objectId = params.objectId;
        const unitId = params.unitId;
        const dateFrom = params.dateFrom;
        const dateTo = params.dateTo ? params.dateTo : dateFrom;
        const guests = params.guests;

        if(
            (objectId || unitId)
            && guests 
            && dateFrom 
            && dateTo 
            && objectGroupId
        ) {
            return $.getJSON(
                location.protocol
                + '//'
                + location.hostname
                + '/services/calc/?'
                + (unitId ? 'unitId=' + unitId : '')
                + (!unitId && objectId ? 'objectId=' + objectId : '')
                + (params.partnerId ? '&partnerId=' + params.partnerId : '')
                + (params.pricelistId ? '&pricelistId=' + params.pricelistId : '')
                + '&guests=' + guests
                + '&dateFrom=' + dateFrom
                + '&dateTo=' + dateTo
                + '&objectGroupId=' + objectGroupId
                + '&language=' + functions.getLang()
                + (params.dynamicPackage ? '&dynamicPackage=' + params.dynamicPackage : '')
            );
        } else {
            return $.Deferred().reject();
        }
    },
    getCalcTotalOld(calcItems) {
        let priceOld = 0;

        calcItems.forEach(item => {
            // popust za djecu ne ulazi u prikaz discount cijene
            if((item.itemType != 'discount' || item.itemDefinitionId == 129 || item.itemDefinitionId == 13) && !item.optional && !item.onSpot) {
                priceOld += item.itemTotal;
            }
        });

        return priceOld;
    }
};

export {
    details,
};
