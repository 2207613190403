import functions from '../functions.js';
import { list } from './list';

$(() => {
    listPackage.init();
});

const listPackage = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {
        personsInObject: 1,
        _page: 1,
        perPage: 12,
        language: functions.getLang(),
        pool: '',
        parking: '',
        pets: '',
        internet: '',
        dateFrom: '',
        dateTo: '',
        dates: null,
        destination: '',
        countryId: '',
        objectGroupId: 1,
        objectTypeId: '',
        guests: '',
        dynamicPackage: true,
        //categoryObject: null, 
        //objectName: null,
    },
    params: {},
    isHome: false,
    $listItems: null,
    filterParams: {
        tagCategoryId: null,
        categoryObject: null, 
        sortBy: null, 
        objectName: null, 
        distanceBeach: null, 
        distanceCenter: null, 
        pool: null, 
        parking: null, 
        pets: null, 
        internet: null
    },
    mainFields: ['dateFrom', 'dateTo', 'destination', 'objectTypeId', 'persons', 'personsInObject', 'sortBy', '_page'],
    init() {
        this.$form = $('.search-form.search-package');
        this.form = this.$form[0];

        this.$filter = $('.search-filter');
        this.filter = this.$filter[0];  

        this.$listItems = $('.list-items');
        this.isHome = this.$form.last().attr('data-ishome') || ! this.$listItems.length;

        /*this.listURL = this.$form.last().attr('data-listurl');
        this.defaults.objectGroupId = this.$form.last().attr('data-objectgroupid');
        this.defaults.countryId = this.defaults.destination = this.$form.last().attr('data-countryid');
        this.defaults.linkedTypeId = this.$form.last().attr('data-linkedtypeid');
        this.filterParams.tagCategoryId = this.$form.last().attr('data-tagcategoryid');*/

        if(! this.form) {
            return;
        }
        
        if(! this.isHome) {

            this.getParams();     
            functions.setFormData(this.form, this.params);
            if(this.filter) {
                functions.setFormData(this.filter, this.params);
            }
            this.splitCalendarDates($('[name=dates]').val());
            if($('[name=guests]').val()) {
                this.params.guests = $('[name=guests]').val();
            }
            this.find(this.params);
        } else {
            functions.setFormData(this.form, this.defaults);
        }

        $('.input-child').on('change', e => {
            e.preventDefault();
            e.stopPropagation();
        });

        if(! functions.isMobile()) {
            this.$form.on('change', e => {
                const $target = $(e.target);
                e.preventDefault();

                this.params = $.extend(this.params, this.defaults, this.filterParams, functions.getFormData($target));
                this.params._page = this.defaults._page;

                functions.setUrlData(this.params, true, true);
            });
        }

        this.$form.on('submit', e => {
            const $target = $(e.target);
            e.preventDefault();

            this.isHome = $target.attr('data-ishome') || this.isHome;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                this.params.countryId = this.params.destination = $target.attr('data-countryid');
                this.params.perPage = $target.attr('data-perpage') || this.defaults.perPage;
            }

            this.params = $.extend(this.params, this.defaults, this.filterParams, functions.getFormData($target));
            this.params._page = this.defaults._page;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                
                location = $target.attr('data-listurl') + '#' + $.param(this.params);
            } else {
                functions.setUrlData(this.params, true, true);
            }

        });

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.params.destination = '';
            $(this.form.destination).val('').trigger('change');
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.stopChangeEvents = true;

            functions.scrollToElement(this.$listItems, 100);
            this.find(this.params);
            this.stopChangeEvents = false;
        });

    },
    getParams() {
        this.params = list.getParams(this.params, this.defaults, this.filterParams);
    },
    splitCalendarDates(dates) {
        list.splitCalendarDates(dates);
    },
    find(params) {
        list.find(params).then((response) => {
            list.findDone(response);
            // LOZAD
            window.observer.observe();
        });
    }
};