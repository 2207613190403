import functions from '../functions.js';
import Slider from "../components/Slider";
import { details } from './details';
import { DetailsCart } from './details-cart.js';

$(() => {
    detailsTravel.init();
})

const detailsTravel = {
    $details: null,
    $form: null,
    form: null,
    availability: {},
    params: {
        objectId: null,
        unitId: null,
        guests: null,
        dates: null,
        dateFrom: null,
        dateTo: null,
        objectGroupId: null,
        calcErrorMessages: null,
        partnerId: null,
    },
    init() {

        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');
        this.params.partnerId = $('[data-partnerid]').length ? $('[data-partnerid]').attr('data-partnerid') : null;

        if(! this.$details[0] && this.params.objectGroupId != 6) {
            return;
        }

        this.params.objectId = this.$details.attr('data-objectid');

        this.getParams();
        this.initDepartureCalendar();
        this.setDefaultDeparture();
        this.setUnitRates();

        this.$cart = $('.details-cart');
        this.cart = new DetailsCart(this.params);
        this.cart.removeCartItem = (index) => {

            if (index) {
                let cartItem = this.cart.cartItems.filter((item, i) => {
                    return i == index;
                });

                cartItem = JSON.parse(cartItem[0]);

                let guests = cartItem.guests.split(',');
                guests = (+guests[0]) + (+guests[1]);

                detailsTravel.availability[cartItem.unitId + "_" + cartItem.dateFrom + "_" + cartItem.dateFrom] += guests;
            }

            DetailsCart.prototype.removeCartItem.call(this.cart, index);
        }

        $('.btn-booking').on('click', e => {
            e.preventDefault();
            if ( !this.cart.cartItems.length ) {
                $('#booking')[0].scrollIntoView();
            } else {
                let cartData = [];

                this.cart.cartItems.forEach(item => {
                    item = JSON.parse(item);
                    cartData.push({
                        'unitId': item.unitId,
                        'dateFrom': item.dateFrom,
                        'dateTo': item.dateTo,
                        'guests': item.guests,
                    });
                });

                let url = $(e.currentTarget).attr('data-href') + '?cart_data=' + JSON.stringify(cartData) + '&addAllOptionals=true';

                if(this.cart.cartItems.length) {
                    location = url;
                } else {
                    $('#detailsCartModal').modal('show');
                    setTimeout(() => {
                        $('#detailsCartModal').modal('hide');
                    }, 2500);
                }
            }
        });

        $('.add-to-cart-custom').on('click', e => {

            e.preventDefault();
            const $target = $(e.currentTarget);
            let unitId = $target.attr('data-unitid');
            let $unit = $target.closest('.unit');

            let guests = $unit.find('[name="guests"]').val();
            guests = guests.split(',');
            guests = (+guests[0]) + (+guests[1]);

            let quantity = detailsTravel.availability[unitId + "_" + detailsTravel.params.dateFrom + "_" + detailsTravel.params.dateFrom];

            quantity = quantity - guests;
            detailsTravel.availability[unitId + "_" + detailsTravel.params.dateFrom + "_" + detailsTravel.params.dateFrom] = quantity;

            let params = {};
            params.unitId = $target.attr('data-unitid');
            params.unitTitle = $target.attr('data-unittitle');
            params.unitCartTotal = +$target.attr('data-unitcarttotal');
            params.unitCartTotalOld = +$target.attr('data-unitcarttotalold');
            params.dateFrom = this.params.dateFrom;
            params.dateTo = this.params.dateTo;
            params.guests = this.params.guests;
            this.cart.currencySymbol = '€';

            this.cart.addToCart(params);
            this.cart.displayCartItems(this.cartItems);
            this.cart.removeCartItem(null);

            $('#detailsCartModal').modal('show');
            setTimeout(() => {
                $('#detailsCartModal').modal('hide');
            }, 2500);

            $('[name="guests"]').val("0,0").trigger('change');

            this.setUnitRates();
        });


        $('.departure').on('click', e => {
            $('.departure').removeClass('active');

            const $target = $(e.currentTarget);
            $target.addClass('active');
            this.params.dateFrom = $target.attr('data-datefrom');
            $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
            this.params.dateTo = $target.attr('data-dateto');

            this.printDates();

            functions.setUrlData({'dateFrom': this.params.dateFrom }, true, true);

        });

        $('[name=guests]').on('change', e => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            this.params.unitId = $target.attr('data-unitid');
            this.params.guests = $target.val();

            if (this.params.guests && this.params.guests != '0,0') this.getCalculation();
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.setUnitRates();

            $('.unit').each((index, unit) => {
                const $unit = $(unit);
                this.params.unitId = $unit.attr('data-unitid');
                this.params.guests = $unit.find('[name=guests]').val();

                if (this.params.guests && this.params.guests != '0,0') this.getCalculation();
            });
        });

    },

    setUnitRates() {
        if(this.params.dateFrom) {
            this.loader();

            const $units = $('.unit[data-unitid]');

            $units.find('.unit-price-old').text('');
            $units.find('.unit-price-total').text('');
            $units.find('.unit-price-label').addClass('d-none');
            $units.find('.unit-price-total-label').addClass('d-none');
            $units.find('.unit-price-total').addClass('d-none');
            $units.find('.unit-price-old').addClass('d-none');

            setTimeout(() => {

                const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + ']');
                const rates = JSON.parse($departure.attr('data-rates'));
                const maxPersons = JSON.parse($departure.attr('data-maxpersonsunits'));

                let excludedUnits = [];

                $('.unit').each((index, elem) => {
                    const unitIds = Object.keys(rates);
                    const unitId = $(elem).attr('data-unitid');

                    ! unitIds.includes(unitId) && excludedUnits.push(unitId);
                });

                if(excludedUnits.length) {
                    excludedUnits.forEach(unitId => {
                        const $unit = $('.unit[data-unitid="' + unitId + '"]');
                        $unit.find('.unit-price').addClass('d-none');
                    });
                }

                $('.unit[data-unitid]').addClass('d-none').addClass('no-price');

                Object.entries(rates).forEach(item => {
                    const unitId = item[0];
                    const rate = item[1];

                    const $unit = $('.unit[data-unitid="' + unitId + '"]');
                    let guests = $unit.find('[name=guests]').val();

                    if ( ! guests || guests == '0,0') {

                        if(maxPersons[unitId]) {
                            $unit.find('.unit-price').removeClass('d-none');
                            $unit.find('.unit-price-total').removeClass('d-none');
                            $unit.find('.unit-price-label').removeClass('d-none');

                            $unit.find('.unit-price-old').toggleClass('d-none', ! rate.priceWithDiscount);
                            $unit.find('.unit-price-old').text(rate.priceWithDiscount ? rate.price : '');
                            $unit.find('.unit-price-total').text(rate.priceWithDiscount ? rate.priceWithDiscount : rate.price);

                            $unit.find('.add-to-cart-custom').addClass('d-none');
                            $unit.find('.btn-soldOut').addClass('d-none');
                            $unit.removeClass('order-1');
                        } else {
                            //console.log(unitId + ': ' + maxPersons[unitId]);
                            $unit.find('.add-to-cart-custom').addClass('d-none');
                            $unit.find('.btn-soldOut').addClass('d-none');
                            $unit.addClass('order-1');
                        }

                    }
                    else {
                        if ($unit.find('.unit-price-old').text()) $unit.find('.unit-price-old').removeClass('d-none');
                        $unit.find('.unit-price-total-label').removeClass('d-none');
                        $unit.find('.unit-price-total').removeClass('d-none');
                    }

                    $unit.removeClass('d-none').removeClass('no-price');

                });

                $('[name=numberOfBeds]').change();
                $('[name=sortBy]').change();

            }, 1000);
        }
    },
    loader($target = null) {
        if ($target) {
            $target.addClass('active');
        } else {
            $('.unit-spinner-block').removeClass('d-none');
            $('.unit-spinner').addClass('active');
        }

        setTimeout(() => {
            if ($target) {
                $target.removeClass('active');
            } else {
                $('.unit-spinner-block').addClass('d-none');
                $('.unit-spinner').removeClass('active');
            }
        }, 800);
    },
    initDepartureCalendar() {
        const $bookingCalendar = $('#booking-calendar-slider');

        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 6;

            let slidesPerView = length < perView ? length : perView;

            if(length < perView) {
                $('.booking-calendar-wrapper').addClass('col-xl-' + (slidesPerView + 2));
                $('.booking-calendar-wrapper').addClass('mx-auto');
            }

            this.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: functions.isMobile() || length > slidesPerView,
                slides: {
                    perView: slidesPerView,
                    spacing: 0,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 6,
                        //centered: true,
                        slides: {
                            perView: 6.2,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.5,
                        },
                    },
                },
            });
        }
    },
    setDefaultDeparture() {

        let $date = $('[data-isavailable="1"]:first');
        let $dateSelected = $('[data-datefrom=' + this.params.dateFrom + ']');
        this.params.dateFrom = ! this.params.dateFrom ? $date.attr('data-datefrom') : ( ! $dateSelected.attr('data-isavailable') ? $date.attr('data-datefrom') : this.params.dateFrom);

        if ($('[data-datefrom=' + this.params.dateFrom + ']').length) {
            $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
            this.params.dateTo = $('[data-datefrom=' + this.params.dateFrom + ']').attr('data-dateto');
        } else {

            $date.addClass('active');
            this.params.dateFrom = $date.attr('data-datefrom');
            this.params.dateTo = $date.attr('data-dateto');
        }

        this.params.guests = $('[name=guests]').val();

        functions.setUrlData({'dateFrom': this.params.dateFrom }, true);

        this.printDates();
    },
    printDates() {
        if (this.params.dateFrom && this.params.dateTo)
        {
            $('.date-from').text(functions.formatDate(this.params.dateFrom));
            $('.date-to').text(functions.formatDate(this.params.dateTo));
            $('.date-from').closest('.h6').removeClass('d-none');
        }
    },
    getParams() {
        this.params = details.getParams(this.params);
    },
    getCalculation() {

        details.getCalculation(this.params)
            .done(response => {
                this.calculationDone(response);
            }).fail(error => {

        });
    },
    calculationDone(response) {

        if(response.status) {
            const data = response.data || [];


            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');
                let quantity = unitData ? +unitData.quantity : 0;

                $unit.find('.unit-price-label').addClass('d-none');

                let key = unitId + "_" + this.params.dateFrom + "_" + this.params.dateFrom;

                if ( ! this.availability[key] && this.availability[key] !== 0) {
                    this.availability[key] = quantity;
                }
                else {
                    quantity = this.availability[key];
                }

                if(details.isBookable(unitData) && quantity) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = +unitCalc.total
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);

                    if(+priceOld.toFixed(2) > +total.toFixed(2)) {
                        $unit.find('.unit-price-old').removeClass('d-none');
                        $unit.find('.unit-price-old').text(currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ','));
                        $unit.find('.add-to-cart-custom').attr('data-unitcarttotalold', priceOld);
                    } else {
                        $unit.find('.unit-price-old').html('');
                        $unit.find('.unit-price-old').addClass('d-none');
                        $unit.find('.add-to-cart-custom').attr('data-unitcarttotalold', null);
                    }

                    $unit.find('.add-to-cart-custom').attr('data-unitcarttotal', total);

                    $unit.find('.add-to-cart-custom').removeClass('d-none');
                    $unit.find('.btn-soldOut').addClass('d-none');
                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));

                    this.printDates();

                    if ($unit.find('.unit-spinner.active').length) {
                        $unit.find('.unit-price-total-label').addClass('d-none');
                        $unit.find('.unit-price-total').addClass('d-none');
                        $unit.find('.unit-price-old').addClass('d-none');
                    }
                } else {
                    $unit.find('.add-to-cart-custom').addClass('d-none');
                    $unit.find('.unit-price-total-label').addClass('d-none');
                    $unit.find('.unit-price-total').addClass('d-none');
                    $unit.find('.unit-price-old').addClass('d-none');
                    $unit.find('.btn-soldOut').removeClass('d-none');
                }
            });
        }
    }
};
