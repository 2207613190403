module.exports = data => {

    data.perPage = data.perPage || 12;
    data.total = data.total || 0;
    data.displayedPages = data.displayedPages || 5;
    data.currentPage = +data.currentPage || 1;

    const numberOfPages = Math.ceil(data.total / data.perPage);

    let start = Math.max(1, data.currentPage - Math.abs(Math.floor(data.displayedPages / 2)));
    let end = start + data.displayedPages;

    if (end > numberOfPages) {
        end = numberOfPages + 1;
        start = Math.max(1, end - data.displayedPages);
    }

    const pages = [];

    for (let i = start; i < end; i++) {
        pages.push(i);
    }

    return `            
            <ul class="pagination justify-content-center">
                <!-- prev page -->                
                <li class="page-item ${ data.currentPage == start ? `disabled` : `` }">
                    <a class="page-link h-100" href="#_page=${data.currentPage - 1}" aria-label="Previous" data-page="${data.currentPage - 1}">
                        <i class="la la-arrow-left la-lg"></i>
                    </a>
                </li>
                <!-- first page -->
                ${numberOfPages && pages[0] != 1 ? `
                    <li class="page-item"><a class="page-link" href="#_page=1" data-page="1">1</a></li>
                ` : ``}
                <!-- pages -->
                ${pages.map(page => `
                    <li class="page-item ${page == data.currentPage ? `active` : ``}"><a class="page-link" href="#_page=${page}" data-page="${page}">${page}</a></li>
                `).join("")}
                <!-- last page -->
                ${numberOfPages && pages[pages.length - 1] != numberOfPages ? `
                    <li class="page-item"><a class="page-link" href="#_page=${numberOfPages}" data-page="${numberOfPages}">${numberOfPages}</a></li>
                ` : ``}
                <!-- next page -->
                <li class="page-item ${ data.currentPage + 1 == end ? `disabled` : `` }">
                        <a class="page-link h-100" href="#_page=${data.currentPage + 1}" aria-label="Next" data-page="${data.currentPage + 1}">
                            <i class="la la-arrow-right la-lg"></i>
                        </a>
                </li>
            </ul>
    `;
};