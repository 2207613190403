$(function () {
    let $tracking = $('.gatracking');

    if ($tracking.length)
    {
        let transactionId = JSON.parse($('[data-gadds]').attr('data-gadds'));
    
            if (transactionId)
            {
                gtag('event', 
                    'conversion', 
                    { 
                        'send_to': 'AW-11010137881/YjD1COHqi7gYEJm-hYIp', 
                        'transaction_id': transactionId 
                    }
                ); 
                
            }

    }
});